import { CircularProgress } from "@mui/material";

const CommonLoader = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: "50%",
        left: "50%",
        zIndex: "10",
        transform: "translate(-50%, -50%)",
        width: "20%",
      }}
    >
      <CircularProgress disableShrink />
      <span style={{ position: "absolute", top: "42px", left: "120px" }}>
        <b>Loading...</b>
      </span>
    </div>
  );
};
export default CommonLoader;
