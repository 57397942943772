import {
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Typography, Grid, TextField } from "@mui/material";
import CommonDialog from "../../common/commonDialog";

const useStyles = makeStyles((theme) => ({
  checkLabelFont: {
    marginLeft: "12px!important",
    color: "#292929!important",
    fontSize: "1rem!important",
  },
  checkFont: {
    display: "flex",
    justifyContent: "start",
    marginLeft: "0px!important",
  },
}));

const DistributorModal = (props) => {
  const classes = useStyles();

  const {
    open,
    onCancel,
    onSave,
    distributorData,
    setDistributorData,
    bmComboList,
    bsmComboList,
    asmComboList,
    coOrdinatorComboList,
    divisionData,
    regionData,
    stateData,
  } = props;
  const onInputChange = (event) => {
    const { name, value } = event.target;
    distributorData[name] = value;
    setDistributorData({ ...distributorData });
  };
  return (
    <CommonDialog
      onCancel={onCancel}
      onSave={onSave}
      open={open}
      primaryButtonLabel={"Save"}
      secondaryButtonLabel={"Cancel"}
      title={" Distributor Master"}
      maxWidth="md"
    >
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-select-currency"
            select
            label="Region"
            defaultValue=""
            size="small"
            name="regionMasterId"
            value={distributorData.regionMasterId}
            onChange={onInputChange}
          >
            {regionData.map((option) => (
              <MenuItem
                key={option.regionMasterId}
                value={option.regionMasterId}
              >
                {option.regionName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {/* <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-select-currency"
            select
            label="Division"
            name="divisionMasterId"
            value={distributorData.divisionMasterId}
            onChange={onInputChange}
            defaultValue=""
            size="small"
          >
             {divisionData.map((option) => (
              <MenuItem key={option.divisionMasterId} value={option.divisionMasterId}>
                {option.divisionName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Main Agent"
            size="small"
            name="mainAgent"
            value={distributorData.mainAgent}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Sub Agent"
            size="small"
            name="subAgent"
            value={distributorData.subAgent}
            onChange={onInputChange}
          />
        </Grid> */}

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Main Party"
            size="small"
            name="mainParty"
            value={distributorData.mainParty}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Sub Party"
            size="small"
            name="subParty"
            value={distributorData.subParty}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField fullWidth id="outlined-search" label="City" size="small" />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-select-currency"
            select
            label="State"
            defaultValue=""
            size="small"
            name="stateMasterId"
            value={distributorData.stateMasterId}
            onChange={onInputChange}
          >
            {stateData.map((option) => (
              <MenuItem key={option.stateMasterId} value={option.stateMasterId}>
                {option.stateName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Address"
            name="address"
            type="text"
            size="small"
            value={distributorData.address}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="SAP Party Code"
            size="small"
            type="number"
            name="sapPartyCode"
            value={distributorData.sapPartyCode}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Password"
            size="small"
            type="text"
            name="loginPin"
            value={distributorData.loginPin}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Mail Id"
            size="small"
            type="mail"
            name="emailId"
            value={distributorData.emailId}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Mobile No"
            size="small"
            type="number"
            name="mobileNo"
            value={distributorData.mobileNo}
            onChange={onInputChange}
          />
        </Grid>

        {/* <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-select-currency"
            select
            label="BM"
            defaultValue=""
            size="small"
            name="bm"
            value={distributorData.bm}
            onChange={onInputChange}
          >
            {bmComboList.map((option) => (
              <MenuItem key={option.userId} value={option.userId}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-select-currency"
            select
            label="BSM"
            defaultValue=""
            size="small"
            name="bsm"
            value={distributorData.bsm}
            onChange={onInputChange}
          >
            {bsmComboList.map((option) => (
              <MenuItem key={option.userId} value={option.userId}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-select-currency"
            select
            label="ASM"
            defaultValue=""
            size="small"
            name="asm"
            value={distributorData.asm}
            onChange={onInputChange}
          >
             {asmComboList.map((option) => (
              <MenuItem key={option.userId} value={option.userId}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-select-currency"
            select
            label="Co-ordinator 1"
            defaultValue=""
            size="small"
            name="coOrdinatorOne"
            value={distributorData.coOrdinatorOne}
            onChange={onInputChange}
          >
             {coOrdinatorComboList.map((option) => (
              <MenuItem key={option.userId} value={option.userId}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-select-currency"
            select
            label="Co-ordinator 2"
            defaultValue=""
            size="small"
            name="coOrdinatorTwo"
            value={distributorData.coOrdinatorTwo}
            onChange={onInputChange}
          ></TextField>
        </Grid> */}

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="GST No"
            size="small"
            name="gstNo"
            value={distributorData.gstNo}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="PAN"
            size="small"
            name="panNo"
            value={distributorData.panNo}
            onChange={onInputChange}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Dixcy Virtual Account"
            size="small"
            type="text"
            name="dixcyVirtualAc"
            value={distributorData.dixcyVirtualAc}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Maximus Virtual Account"
            size="small"
            type="text"
            name="maximusVirtualAc"
            value={distributorData.maximusVirtualAc}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Levis Virtual Account"
            size="small"
            type="text"
            name="levisVirtualAc"
            value={distributorData.levisVirtualAc}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Thermal Virtual Account"
            size="small"
            type="text"
            name="thermalVirtualAc"
            value={distributorData.thermalVirtualAc}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Enamor Virtual Account"
            size="small"
            type="text"
            name="enamorVirtualAc"
            value={distributorData.enamorVirtualAc}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="IFSC"
            size="small"
            type="text"
            name="ifscCode"
            value={distributorData.ifscCode}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Client Code"
            size="small"
            type="text"
            name="clientCode"
            value={distributorData.clientCode}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Beneficiary Name"
            size="small"
            type="text"
            name="beneficiaryName"
            value={distributorData.beneficiaryName}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Bank Name"
            size="small"
            type="text"
            name="bankName"
            value={distributorData.bankName}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Bank Branch"
            size="small"
            type="text"
            name="bankBranch"
            value={distributorData.bankBranch}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={12} xs={12}>
          <FormControl>
            <FormLabel
              id="demo-row-radio-buttons-group-label"
              className={classes.checkLabelFont}
            >
              Status
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="status"
              value={distributorData.status}
              onChange={onInputChange}
            >
              <FormControlLabel
                value="Y"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#f91e26",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: 23,
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    variant="body1"
                    fontSize={15}
                    sx={{ color: "#292929" }}
                  >
                    ACTIVE
                  </Typography>
                }
                className={classes.checkFont}
              />
              <FormControlLabel
                value="N"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#f91e26",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: 23,
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    variant="body1"
                    fontSize={15}
                    sx={{ color: "#292929" }}
                  >
                    INACTIVE
                  </Typography>
                }
                className={classes.checkFont}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </CommonDialog>
  );
};
export default DistributorModal;
