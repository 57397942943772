import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Container, Row } from "reactstrap";
import { ToastContainer } from "react-toastify";
import { Button, MenuItem } from "@mui/material";
import { Grid, TextField } from "@mui/material";
import {
  PersonSearch,
  Refresh,
  SimCardDownloadRounded,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import {
  exportToExcelOpenCredit,
  getAllOpenCredit,
  searchOpenCreditData,
} from "../../api/service";
import CommonLoader from "../../common/commonLoader";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../../common/breadcrumb";
import {
  CardOpenCreditValue,
  openCreditTableData1,
  openCreditTableHeader1,
} from "../../common/commonCardConstant";
import CommonCardDesign from "../../common/commonCardDesign";
import { Col } from "react-bootstrap";
import moment from "moment";
import { useCustomerCode } from "../../context/CustomerCodeProvider";
import { formatMoney } from "../../../constants/utils";

const useStyles = makeStyles((theme) => ({
  btnClass: {
    padding: "0.5rem 1.5rem!important",
    fontWeight: 600,
  },

  btnJust: {
    display: "flex!important",
    justifyContent: "end!important",
  },

  cardbody: {
    padding: `${theme.spacing(1.8, 1.3)} !important`,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(0)} !important`,
      margin: `${theme.spacing(0, 1)} !important`,
    },
  },

  muiGrid: {
    marginTop: "-2vh",
    [theme.breakpoints.down("sm")]: {
      lineHeight: 5,
    },
  },
}));

const OpenCredit = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(1000);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [consolidateData, setConsolidateData] = useState([]);
  const { selectedCustomerCode, selectedCustomerName } = useCustomerCode();

  const filterInitialValues = {
    searchDate: "", //moment(new Date()).format("YYYY-MM-DD"),
  };

  const [searchData, setSearchData] = useState(filterInitialValues);
  useEffect(() => {
    filterData();
  }, [selectedCustomerCode]);

  const onFilterInputChange = (event) => {
    const { name, value } = event.target;
    searchData[name] = value;
    setSearchData({ ...searchData });
  };

  /* useEffect(() => {
    loadListData();
  }, []); */

  const loadListData = () => {
    setLoading(true);
    const customerCodeParam = location?.state?.customerCodeParam
      ? location?.state?.customerCodeParam
      : "";
    searchData.searchCustomerCode = customerCodeParam;
    setSearchData({
      ...searchData,
    });

    getAllOpenCredit(customerCodeParam, "", page, rowsPerPage).then((res) => {
      setLoading(false);
      let listData = res.response.responseObject;
      let consolidateListData = res.response.responseMap.consolidateList;

      const divisionParam = location?.state?.divisionParam;
      if (divisionParam) {
        listData = listData.filter((item) => item.division === divisionParam);
        consolidateListData = consolidateListData.filter(
          (item) => item.division === divisionParam
        );
      }
      setData(listData);
      setConsolidateData(consolidateListData);
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const filterData = () => {
    /* searchOpenCreditData(searchData, page, rowsPerPage).then((res) => {
      setData(res.responseMap.data);
    }); */
    setLoading(true);
    getAllOpenCredit(
      selectedCustomerCode,
      searchData.searchDate,
      page,
      rowsPerPage
    ).then((res) => {
      setLoading(false);
      let listData = res.response.responseObject;
      let consolidateListData = res.response.responseMap.consolidateList;

      const divisionParam = location?.state?.divisionParam;
      if (divisionParam) {
        listData = listData.filter((item) => item.division === divisionParam);
        consolidateListData = consolidateListData.filter(
          (item) => item.division === divisionParam
        );
      }
      setData(listData);
      setConsolidateData(consolidateListData);
    });
  };

  const exportData = () => {
    setLoading(true);
    exportToExcelOpenCredit(
      selectedCustomerCode,
      `${selectedCustomerName.replace(" ", "_")}_${moment(new Date()).format(
        "DD_MM_YYYY_HH_mm_SS"
      )}`
    ).then(() => {
      setLoading(false);
    });
  };

  return (
    <Fragment>
      {loading ? <CommonLoader /> : null}
      <Breadcrumb
        title="Open Credit"
        parent={
          <div className={classes.btnJust}>
            <Button
              color={"secondary"}
              variant="contained"
              type="button"
              className={`${classes.btnClass}`}
              //startIcon={<Add></Add>}
              onClick={() => {
                history(`${process.env.PUBLIC_URL}/reports/openCreditHistory`);
              }}
            >
              View Credit Note History
            </Button>
          </div>
        }
      />
      {/* <!-- Container-fluid starts--> */}
      <Container fluid={true}>
        <Row>
          {/* <CommonAccordion accordionTitle={"Advanced Search"}>
            <Grid container columnSpacing={2} className={classes.muiGrid}>
              <Grid item sm={4} xs={12}>
                <TextField
                  fullWidth
                  label="Customer Code"
                  size="small"
                  select
                  id="fullWidth"
                  name="searchCustomerCode"
                  value={searchData.searchCustomerCode}
                  onChange={onFilterInputChange}
                ></TextField>
              </Grid>
              <Grid item sm={4} xs={12}>
                <TextField
                  fullWidth
                  id="outlined-select-currency"
                  select
                  label="Customer Name"
                  size="small"
                  name="searchCustomerName"
                  value={searchData.searchCustomerName}
                  onChange={onFilterInputChange}
                ></TextField>
              </Grid>

              <Grid
                item
                sm={4}
                xs={12}
                sx={{
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  type="button"
                  className={classes.btnClass}
                  onClick={() => filterData()}
                >
                  <PersonSearch></PersonSearch>
                </Button>
                <Button
                  color="primary"
                  sx={{ marginLeft: 1 }}
                  variant="contained"
                  type="button"
                  className={classes.btnClass}
                  onClick={() => {
                    setSearchData(filterInitialValues);
                    loadListData();
                  }}
                >
                  <Refresh></Refresh>
                </Button>
              </Grid>
            </Grid>
          </CommonAccordion> */}
          <Col lg={6} xs={12}>
            <Card className="height-equal">
              <CardBody className={classes.cardbody}>
                <div className="user-status table-responsive text-center">
                  <table className="table table-bordernone mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Division</th>
                        <th scope="col">Customer Code</th>
                        <th scope="col">CN Amount</th>
                        <th scope="col">Balance CN AMOUNT</th>
                      </tr>
                    </thead>
                    <tbody>
                      {consolidateData.map((data) => (
                        <tr>
                          <td>{data.divisionName}</td>
                          <td className="digits">{data.customerCode}</td>
                          <td className="digits">
                            {formatMoney(data.cnAmt?.toFixed(0))}
                          </td>
                          <td className="digits">
                            {formatMoney(data.balanceCnAmt?.toFixed(0))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={6} xs={12}>
            <Card>
              <CardBody className={classes.cardbody}>
                <Grid container spacing={1.5} className={classes.muiGrid}>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Date"
                      size="small"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      id="fullWidth"
                      name="searchDate"
                      value={searchData.searchDate}
                      onChange={onFilterInputChange}
                    />
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    sx={{
                      margin: "auto",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      type="button"
                      className={classes.btnClass}
                      onClick={() => filterData()}
                    >
                      <PersonSearch></PersonSearch>
                    </Button>
                    <Button
                      color="primary"
                      sx={{ marginLeft: 1 }}
                      variant="contained"
                      type="button"
                      className={classes.btnClass}
                      onClick={() => {
                        setSearchData(filterInitialValues);
                        loadListData();
                      }}
                    >
                      <Refresh></Refresh>
                    </Button>
                    <Button
                      color="primary"
                      sx={{ marginLeft: 1 }}
                      variant="contained"
                      type="button"
                      className={classes.btnClass}
                      onClick={() => {
                        exportData();
                      }}
                    >
                      <SimCardDownloadRounded></SimCardDownloadRounded>
                    </Button>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </Col>

          <CardBody className={classes.cardbody}>
            <div>
              <CommonCardDesign
                tableData={data}
                tableHeaders={openCreditTableHeader1}
                tableColumns={openCreditTableData1}
                tableModal={CardOpenCreditValue}
                rowsPerPageOptions={[25, 50, 100]}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ float: "right" }}
                displayPagination={false}
                showPdf={true}
                from={"Open Credit"}
              />

              {/* <CommonTable
                  tableData={data}
                  tableHeaders={openCreditTableHeader}
                  tableColumns={openCreditTableData}
                  rowsPerPageOptions={[25, 50, 100]}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{ float: "right" }}
                  displayPagination={false}
                /> */}
            </div>
          </CardBody>

          {/* <Card>
            <CardBody className={classes.cardbody}>
              <div>
                <CommonTable
                  tableData={consolidateData}
                  tableHeaders={openCreditConsolidateTableHeader}
                  tableColumns={openCreditConsolidateTableData}
                  rowsPerPageOptions={[25, 50, 100]}
                  count={consolidateData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{ float: "right" }}
                  displayPagination={false}
                />
              </div>
            </CardBody>
          </Card> */}
        </Row>
      </Container>
      {/* <!-- Container-fluid Ends--> */}
      <ToastContainer autoClose={1000} />
    </Fragment>
  );
};

export default OpenCredit;
